<template>
  <div class="section_wrap pt-50 type-bbs" style="padding-bottom: 70px">
    <div class="ml-20 mr-20" style="flex: 1">
      <div class="under-line pb-10 flex-row title">
        <h3 class="mr-10">{{ program.title }}</h3>
        <h6>마피아 서비스의 공지사항을 확인하세요.</h6>
      </div>
      <ul class="mt-30 ml-30">
        <li v-for="item in items" :key="item.notice_number" class="under-line mb-20">
          <a @click="$router.push({name : 'bbs_detail', query : { notice_number : item.notice_number, notice_title : item.notice_title, notice_reg_date : item.registdate, notice_new_fg : item.new_reg_fg  }})">
            <div class="text-black">{{ item.notice_title }}</div>
            <div style="display: flex; justify-content: space-between; flex-direction: row; width: 220px;"
                 class="mt-10">
              <div class="text-gray">{{ item.registdate }}</div>
              <div class="font-weight-bold text-red" v-if="item.new_reg_fg === 'Y'">NEW</div>
            </div>
          </a>
        </li>
      </ul>

<!--      <div class="pagination" v-if="!items.page_info">
        <a href="#">&laquo;</a>
        <a href="#">1</a>
        <a href="#" class="active">2</a>
        <a href="#">3</a>
        <a href="#">4</a>
        <a href="#">5</a>
        <a href="#">6</a>
        <a href="#">&raquo;</a>
      </div>-->

      <div class="pagination">
        <button @click="page_unit--" :disabled="canDecrement">
          <v-icon
              style="color: var(--gray02); width: 10px"
          >mdi-chevron-double-left
          </v-icon>
        </button>
        <button v-for="page in pages" :key="page" :class="{'active' : currentPage === page }" @click="currentPage = page">{{ page }}</button>
        <button @click="page_unit++" :disabled="canIncrement">
          <v-icon
              style="color: var(--gray02); width: 10px"
          >mdi-chevron-double-right
          </v-icon>
        </button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "bbs"
  , props: ['user']
  , data: function () {
    return {
      program: {
        name: '커뮤니티'
        , title: '공지사항'
        , b_code: this.$route.params.b_code
        , type: 'mypage'
      }
      , items: []
      , currentPage: 1
      , page_unit : 1
      , page_tot_count : 1
    }
  }

  , computed: {
    pages() {

      let endPage = this.page_unit * 5
      let startPage = endPage - 4

      if (this.page_tot_count < endPage) {
        endPage = (endPage - (endPage - this.page_tot_count))
      }

      let pages = []
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i)
      }
      return pages
    }
    , canDecrement() {
      if (this.page_unit <= 1) {
        return true
      }
      return false
    }

    , canIncrement() {
      if (this.page_tot_count < (this.page_unit * 5)) {
        return true
      }
      return false
    }

  }

  ,  watch: {
    currentPage() {
      this.$nextTick(() => {
        // 페이지 변경시에는 데이터를 업데이트 합니다.
        // 이곳에서 API를 호출하여 새로운 데이터를 가져올 수 있습니다.
        console.log("Page changed: ", this.currentPage);
        this.getNotice(this.currentPage)
      });
    },
  }

  , methods: {

    getNotice: async function (page_number = 1) {
      try {
        this.$bus.$emit('on', true)

        const result = await this.$request.init({
          method: 'post'
          , url: this.$api_url.api_path.get_notice_list
          , data: {
            member_number: this.user.member_number
            , page_number: page_number
            , pagerecnum: this.$language.base.pagerecnum
          }
          , type: true
        })
        if (result.success) {
          this.items = result.data.notice_mttrs_list
          this.page_tot_count = result.data.page_info.page_tot_count
        } else {
          throw result.message
        }
      } catch (e) {
        console.log(e)
        this.$bus.$emit('notify', {type: 'error', message: e})
      } finally {
        this.$bus.$emit('on', false)
      }
    }

  }

  , created() {
    this.$emit('onLoad', this.program)
    this.getNotice()
  }
}
</script>

<style scoped>
.type-bbs .section_wrap {
  display: flex;
  flex-wrap: wrap;
}

.type-bbs h6 {
  line-height: 260%;
}

.type-bbs li.under-line {
  padding-bottom: 10px;
}

.type-bbs .title {
  border-bottom: 1px solid #b2b2b2;
  box-sizing: content-box
}

.type-bbs .text-gray {
  color: var(--light-Gray03);
}

.type-bbs .text-red {
  color: var(--red);
}

.type-bbs .text-black {
  color: var(--black);
}

.detail_title {
  width: 35px;
  height: 35px;
  line-height: 200%;
  background-color: #e5e5e5;
  border-radius: 20px
}

.btn-inline {
  background-color: white;
  color: var(--blue02);
  border: 2px solid var(--blue02); /* Green */
}

.btn-inline:hover {
  background-color: var(--blue02) !important;
  color: white;
}


.type-bbs  .pagination {
  display: inline-block;
}

.type-bbs  .pagination button {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 0 4px;
}

.type-bbs .pagination button.active {
  background-color: var(--blue02);
  color: white;
  border: 2px solid var(--blue02);
}

.type-bbs .pagination button:hover:not(.active) {background-color: #ddd;}

</style>